import Footer from "../Footer/Footer";
import TurfList from "./Turflist";
import Header from "../Home/Header";
import React from "react";

const Turfhome = () => {
  return (
    <>
      <Header></Header>
      <TurfList></TurfList>
      <Footer></Footer>
    </>
  );
};
export default Turfhome;
