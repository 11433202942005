import React, { useState, useEffect } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

const Payment = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [data, setData] = useState(props.data);
  const [paytmPrice, setPaytmPrice] = useState(props.data.subTotal);
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [paymentStatus, setPaymentStatus] = useState(null);
  console.log(userData, "data");
  const [bookingsData, setBookingsData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkPayments?email=${userData.email}`
      );
      setBookingsData(response.data.length, "response.data.length");
      console.log(response.data.length, "response.data.length");
      if (response.data.length === 0) {
        const discountPrice = props.data.subTotal - props.data.subTotal * 0.1;
        setPaytmPrice(discountPrice);
      } else {
        const discountPrice = props.data.subTotal - props.data.subTotal * 0.05;
        setPaytmPrice(discountPrice);
      }
    } catch (error) {
      console.log("failed", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const orderSummaryItems = [
    { icon: "fa-regular fa-building", text: data.turfname },
    { icon: "feather-calendar", text: data.date },
    {
      icon: "feather-clock",
      text: data.time.map((item) => item.selecttime).join(", "),
    },
  ];

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const sendEmail = async (paymentdata) => {
    const emailParams = {
      turfname: paymentdata.get("turfname"),
      date: paymentdata.get("date"),
      time: paymentdata.get("time"),
      price: paymentdata.get("price"),
      totalprice: paymentdata.get("totalprice"),
      name: paymentdata.get("name"),
      email: paymentdata.get("email"),
      mobile: paymentdata.get("mobile"),
      bookingid: paymentdata.get("bookingid"),
      razorPayID: paymentdata.get("razorPayID"),
    };

    try {
      await emailjs.send(
        "service_7fvxkss",
        "template_u8lcyg5",
        emailParams,
        "raGWxa28UGG-RU5XD"
      );
      console.log("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSavepayment = async (rid) => {
    const getCurrentDateTime = () => {
      const now = new Date();

      const date = String(now.getDate()).padStart(2, "0");
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const year = now.getFullYear();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      return `${date}+${month}+${year}_${hours}${minutes}${seconds}`;
    };

    const secretId = `SecIdLL+${getCurrentDateTime()}+${String(
      new Date().getSeconds()
    ).padStart(2, "0")}`;

    const paymentdata = {
      turfName: data.turfname,
      date: data.date,
      time: data.time.map((item) => item.selecttime).join(", "),
      price: data.price,
      totalPrice: paytmPrice,
      name: userData.username,
      email: userData.email,
      mobileNumber: userData.mobileNumber,
      merchantUserId: `MUID${Date.now()}`,
      eventId: "",
      entryFee: "",
      secretId: secretId,
    };
    try {
      const response = await axios.post(
        "https://phonepaylawnlink.dremerz.net/create-order",
        paymentdata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Full API Response:", response);

      const phonePeResponse = response.data;
      if (phonePeResponse.success) {
        const paymentPageUrl =
          phonePeResponse.data?.instrumentResponse?.redirectInfo?.url;

        if (paymentPageUrl) {
          window.location.href = paymentPageUrl;
        } else {
          throw new Error("Payment page URL not found");
        }
      } else {
        throw new Error(phonePeResponse.message || "Payment initiation failed");
      }
    } catch (error) {
      console.error("Payment Error:", error);
      setPaymentStatus(
        error.message || "Payment initiation failed. Please try again."
      );
    }
  };

  return (
    <>
      <div className="container">
        <div className="content">
          <section>
            <div className="text-center mb-40">
              <h3 className="mb-1">Payment</h3>
              <p className="sub-title mb-0">
                Secure your booking, complete payment, and enjoy our
                sophisticated facilities
              </p>
            </div>
            <div className="master-academy dull-whitesmoke-bg card mb-40">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="d-sm-flex justify-content-start align-items-center">
                  <a href="#">
                    <img
                      className="corner-radius-10"
                      src={`https://api.dremerz.net/uploads/${data.image}`}
                      alt="Venue"
                    />
                  </a>
                  <div className="info">
                    <h3 className="mb-2">{data.turfname}</h3>
                    <p>{data.description}</p>
                    <p>{data.address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row checkout">
              <div className="col-lg-7">
                <div className="card booking-details">
                  <h3 className="border-bottom">Order Summary</h3>
                  <ul className="list-unstyled">
                    {orderSummaryItems.map((item, index) => (
                      <li key={index}>
                        <i className={`${item.icon} me-2`}></i>
                        {item.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <aside className="card payment-modes">
                  <h3 className="border-bottom">
                    Checkout Total Rs.{props.data.subTotal}
                  </h3>
                  <div className="form-check d-flex justify-content-start align-items-center policy">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="policy"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                    />
                    <label className="form-check-label" htmlFor="policy">
                      By clicking 'Proceed', I agree to Lawnlink{" "}
                      <a href="privacy-policy#">Privacy Policy</a> and{" "}
                      <a href="terms-condition#">Terms of Use</a>
                    </label>
                  </div>
                  <div className="d-grid btn-block">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "10px",
                      }}
                    >
                      🎉 Congratulations! You've unlocked the{" "}
                      <strong style={{ color: "green" }}>
                        {bookingsData == 0
                          ? "Exclusive 10% Off Coupon"
                          : "Special 5% Off Coupon"}
                      </strong>{" "}
                      for your Booking! 🎉
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!termsAccepted}
                      onClick={handleSavepayment}
                    >
                      Proceed Rs. {paytmPrice}
                    </button>

                    <br></br>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Payment;
