import React from "react";
import "aos/dist/aos.css";
import { MapPin } from "react-feather";
import { Button } from "react-bootstrap";

const venues = [
  {
    id: 1,
    imgSrc: "assets/img/venues/venues-04.jpg",
    name: "GreenField Sports",
    address: "1 Crowthorne Road, 4th Street, Chennai",
    rating: "20%",
    reviews: "300 Reviews",
  },
  {
    id: 2,
    imgSrc: "assets/img/venues/venues-05.jpg",
    name: "GreenField Sports",
    address: "1 Crowthorne Road, 4th Street, Chennai",
    rating: "20%",
    reviews: "300 Reviews",
  },
  {
    id: 3,
    imgSrc: "assets/img/venues/venues-06.jpg",
    name: "GreenField Sports",
    address: "1 Crowthorne Road, 4th Street, Chennai",
    rating: "20%",
    reviews: "300 Reviews",
  },
  {
    id: 4,
    imgSrc: "assets/img/venues/venues-07.jpg",
    name: "GreenField Sports",
    address: "1 Crowthorne Road, 4th Street, Chennai",
    rating: "20%",
    reviews: "300 Reviews",
  },
  {
    id: 5,
    imgSrc: "assets/img/venues/venues-08.jpg",
    name: "GreenField Sports",
    address: "1 Crowthorne Road, 4th Street, Chennai",
    rating: "20%",
    reviews: "300 Reviews",
  },
];

const VenueSection = () => {
  return (
    <section className="section court-near">
      <div className="container">
        <div className="section-heading aos" data-aos="fade-up">
          <h2>
            Find Turf's <span>On Your Convenient Hours</span>
          </h2>
          <p className="sub-title">
            Discover nearby Turf's for convenient and accessible gameplay.
          </p>
        </div>
      </div>
    </section>
  );
};

export default VenueSection;
