import Homefeature from "../Admin/Homefeaturedadmin";
import Premiumoffers from "../Admin/Premiumoffers";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import Addservice from "../Admin/Addservice";
import Howitworks from "../Admin/Howitworks";
import OurFeatures from "../Admin/Ourservicesadmin";
import FrequentlyAskedQuestions from "../Admin/Faqadmin";
import Plans from "../Admin/Excellentplans";
import React from "react";

const Superadmin = () => {
  return (
    <>
      <Navbaradmin />
      {/* <Homefeature /> */}
      <Premiumoffers />
      {/* <Howitworks /> */}
      <Addservice />
      <OurFeatures />
      <FrequentlyAskedQuestions />
      {/* <Plans /> */}
    </>
  );
};
export default Superadmin;
