import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
const Response = () => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        // Extract the query parameters
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");

        const response = await axios.post(
          "https://phonepaylawnlink.dremerz.net/paymentcallback?id=" + id
        );
        console.log(response);
      } catch (error) {
        console.error("Error logging payment:", error);
      }
    };
    handleRedirect();
  }, []);

  const handleSuccess = async (transactionId, amount) => {
    try {
      // Send success data to the backend API
      const response = await axios.post(
        "https://api.yoursite.com/record-payment",
        {
          transactionId,
          amount,
          status: "success",
        }
      );

      if (response.data.success) {
        setMessage("Payment successful! Your booking has been confirmed.");
        setStatus("success");
      } else {
        throw new Error("Failed to log the payment. Please contact support.");
      }
    } catch (error) {
      console.error("Error logging payment:", error);
      setMessage(
        "Payment succeeded, but there was an issue recording your transaction."
      );
      setStatus("error");
    }
  };

  const handleFailure = () => {
    setMessage("Payment failed. Please try again or contact support.");
    setStatus("failure");
  };

  return (
    <div className="container mt-5">
      <div className="text-center">
        {isLoading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            {status === "success" && (
              <div className="alert alert-success" role="alert">
                {message}
              </div>
            )}
            {status === "failure" && (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            )}
            {status === "error" && (
              <div className="alert alert-warning" role="alert">
                {message}
              </div>
            )}
          </div>
        )}
        <div className="mt-4">
          <button
            className="btn btn-primary"
            onClick={() => (window.location.href = "/shopping")}
          >
            Back to Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default Response;
