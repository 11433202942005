import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import Bookhourlycourt from "./Bookhourlycourt";
import Hourlypackagelist from "./Hourlypackagelist";
import Packageads from "./Packageads";
import React from "react";

const Hourlyhome = () => {
  return (
    <>
      <Header></Header>
      {/* <Packageads></Packageads> */}
      {/* <Bookhourlycourt></Bookhourlycourt> */}

      <Hourlypackagelist></Hourlypackagelist>
      <Footer></Footer>
    </>
  );
};
export default Hourlyhome;
