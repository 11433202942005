import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbarheader from "../Navbaradmin/Navbaradmin";
import { utils, writeFile } from "xlsx";
import "./Invoicelist.css";

const Invoicelist = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkPayments"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      String(val).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const removeDuplicates = (data) => {
    const uniqueData = [];
    const seen = new Set();

    data.forEach((item) => {
      const uniqueKey = `${item.name}-${item.email}-${item.mobile}`;
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        uniqueData.push({
          Name: item.name,
          Email: item.email,
          Mobile: item.mobile,
        });
      }
    });

    return uniqueData;
  };

  const handleDownload = () => {
    const uniqueEntries = removeDuplicates(filteredData);

    const dataWithSNo = uniqueEntries.map((item, index) => ({
      "S.No": index + 1,
      Name: item.Name,
      Email: item.Email,
      Mobile: item.Mobile,
    }));

    const worksheet = utils.json_to_sheet(dataWithSNo);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Invoice Data");
    writeFile(workbook, "Invoice_Data_with_SNo.xlsx");
  };

  return (
    <div>
      <Navbarheader />
      <br />
      <div className="container" style={{ marginTop: "100px" }}>
        <h2>Invoice List</h2>

        {/* Search Bar */}
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Download Button */}
        <div className="mb-3">
          <button className="btn btn-primary" onClick={handleDownload}>
            Download Excel
          </button>
        </div>

        {/* Table */}
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>ID</th>
              <th style={{ width: "100px" }}>Turf Name</th>
              <th style={{ width: "100px" }}>Date</th>
              <th style={{ width: "100px" }}>Time</th>
              <th style={{ width: "100px" }}>Total Price</th>
              <th style={{ width: "100px" }}>Name</th>
              <th style={{ width: "150px", wordBreak: "break-word" }}>Email</th>
              <th style={{ width: "175px", wordBreak: "break-word" }}>
                Mobile{" "}
              </th>
              <th style={{ width: "175px", wordBreak: "break-word" }}>
                Booking ID
              </th>
              <th style={{ width: "160px", wordBreak: "break-word" }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.toReversed().map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.turfName}</td>
                  <td>{item.date}</td>
                  <td>{item.time}</td>
                  <td>{item.totalPrice}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobileNumber}</td>
                  <td>{item.merchantUserId}</td>
                  <td>{item.statusCode}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No matching records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoicelist;
