import { useEffect, useState } from "react";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import axios from "axios";
import React from "react";

function Admin() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkeventlist"
      );
      setData(response.data);
      console.log(response.data, "getdata");
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleDelete = async (id) => {
    const Isconfirm = window.confirm(
      "are you sure want to delete this event ?"
    );
    if (!Isconfirm) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkeventlist/${id}`
        );
        // Remove the deleted item from the local state
        setData(data.filter((item) => item.id !== id));
        console.log("Item deleted successfully");
      } catch (error) {
        console.error("Error deleting item", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  useEffect(() => {
    getdata();
  }, []);

  const filteredData = data.filter(
    (item) =>
      (item.turfname || "").toLowerCase().includes(searchTerm) ||
      (item.matchType || "").toLowerCase().includes(searchTerm) ||
      (item.location || "").toLowerCase().includes(searchTerm) ||
      (item.contactNo || "").includes(searchTerm)
  );

  return (
    <>
      <Navbaradmin />
      <div className="container" style={{ marginTop: "100px" }}>
        <h2>Events Management</h2>

        <div
          className="admin-search-bar"
          style={{ textAlign: "right", marginBottom: "20px" }}
        >
          <input
            type="text"
            placeholder="Search Here..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
        </div>

        <table className="admin-user-table">
          <thead className="admin-userlist-header">
            <tr>
              <th>S.No</th>
              <th>turfname</th>
              <th>matchType</th>
              <th>contactNo</th>
              <th>location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, i) => (
                <tr key={item.id}>
                  <td>{i + 1}</td>
                  <td>{item.turfname}</td>
                  <td>{item.matchType}</td>
                  <td>{item.contactNo}</td>
                  <td>{item.location}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Admin;
