import { useEffect, useState } from "react";
import "./Admindevdemo.css";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import axios from "axios";
import React from "react";

function Admin() {
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getUser = () => {
    fetch("https://api.dremerz.net/api/lawnlinksaveuser")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.length > 0) {
          setUser(json);
        }
      });
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/signin";
  };

  const downloadExcel = () => {
    const rows = [["S.no", "UserName", "Email", "Mobile Number"]];
    user.forEach((item, index) => {
      rows.push([index + 1, item.user_name, item.email, item.mobileNumber]);
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_details.csv");
    document.body.appendChild(link);
    link.click();
  };

  const verifyUser = async (item) => {
    item.isVerify = true;
    const response = await axios.put(
      "https://api.dremerz.net/api/lawnlinksaveuser/" + item.id + "/",
      item
    );
    alert("User verified");
  };

  const deleteUser = async (item) => {
    const isConfirm = window.confirm(
      "Are you sure you want to delete the user permanently?"
    );
    if (!isConfirm) {
      return;
    } else {
      const response = await axios.delete(
        "https://api.dremerz.net/api/lawnlinksaveuser/" + item.id + "/"
      );
      alert("User deleted");
      getUser();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredUsers = user.filter(
    (item) =>
      item.username.toLowerCase().includes(searchTerm) ||
      item.email.toLowerCase().includes(searchTerm) ||
      item.mobileNumber.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Navbaradmin />
      <div className="container" style={{ marginTop: "100px" }}>
        <h2>Admin Management</h2>

        <div className="admin-search-bar">
          <input
            type="text"
            placeholder="Search Here..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
        </div>

        <table className="admin-user-table">
          <thead className="admin-userlist-header">
            <tr>
              <th>S.No</th>
              <th>Username</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Verify Status</th>
              <th>Delete Account</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{item.mobileNumber}</td>
                <td>
                  {item.isVerify == true ? (
                    "Verified"
                  ) : (
                    <button
                      className="admin-verify-button"
                      onClick={() => verifyUser(item)}
                    >
                      Verify
                    </button>
                  )}
                </td>
                <td>
                  <button
                    className="admin-delete-button"
                    onClick={() => deleteUser(item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button className="admin-logout-button" onClick={handleLogout}>
          Logout
        </button>
        <button className="admin-download-button" onClick={downloadExcel}>
          Download Excel
        </button>
      </div>
    </>
  );
}

export default Admin;
