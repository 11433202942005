import { useState, useEffect } from "react";
import "./Hero-section.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React from "react";

import Form from "react-bootstrap/Form";

const Hero = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [category1, setCategory1] = useState("");
  const [location, setLocation] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("user")) || {};
  const username = userData.username || "guest";

  console.log(location, "location");
  console.log(category1, "category1");

  const handlesearch = (event) => {
    event.preventDefault();
    console.log(location, "location");
    console.log(category1, "category1");

    sessionStorage.setItem("location", location);
    sessionStorage.setItem("category", category1);

    if (category1 === "turfs") {
      navigate("/shopping");
    } else if (category1 === "events") {
      navigate("/events");
    } else {
      navigate("/gyms");
    }
  };

  const getturflocation = async () => {
    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkregisteredturfs`
    );
    const truflocations = response.data.map((item) => item.location);
    const originalturflocation = new Set(truflocations);
    setData(Array.from(originalturflocation));
  };

  const geteventlocation = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkeventlist"
    );
    const eventlocation = response.data.map((item) => item.location);
    const originaleventlocation = new Set(eventlocation);
    setData2(Array.from(originaleventlocation));
  };

  const getgymlocation = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkregisteredgyms"
    );
    const gymlocation = response.data.map((item) => item.location);
    const originalgymlocation = new Set(gymlocation);
    setData3(Array.from(originalgymlocation));
  };

  const handleCatChange = (e) => {
    setCategory1(e.target.value);
  };
  const handleLocChange = (e) => {
    setLocation(e.target.value);
  };

  useEffect(() => {
    getturflocation();
    geteventlocation();
    getgymlocation();
  }, []);

  return (
    <section
      className="hero-section"
      style={{
        height: window.innerWidth <= 786 ? "100px" : "",
      }}
    >
      <div className="banner-cock-one">
        <img src="assets/img/icons/banner-cock1.svg" alt="Banner" />
      </div>
      <div className="banner-shapes">
        <div className="banner-dot-one">
          <span></span>
        </div>
        <div className="banner-cock-two">
          <img src="assets/img/icons/banner-cock2.svg" alt="Banner" />
          <span></span>
        </div>
        <div className="banner-dot-two">
          <span></span>
        </div>
      </div>
      <div className="container">
        <div className="home-banner">
          <div className="row align-items-center w-100">
            <div className="col-lg-7 col-md-10 mx-auto">
              <div
                className="section-search aos"
                data-aos="fade-up"
                style={{
                  marginTop: window.innerWidth <= 786 ? "-150px" : "",
                }}
              >
                <h4>
                  Hii, &nbsp;{username}
                  <br />
                  Browse our selection of pristine turfs for your next game
                </h4>

                <h1>
                  Choose Your <span>Turf</span> and Start Your Match
                </h1>
                <p className="sub-info">
                  Plan your matches with confidence on our top-tier turf
                  facilities with Experience unmatched quality and service when
                  you book with us
                </p>
                <div className="search-box">
                  <form onSubmit={handlesearch}>
                    <div className="search-input line">
                      <label>Select </label>

                      <Form.Select
                        aria-label="Select Category"
                        value={category1}
                        onChange={handleCatChange}
                        name="category"
                        style={{ border: "none", backgroundColor: "#F9F9F6" }}
                      >
                        <option value="">Category</option>
                        <option value="turfs">Turfs</option>
                        <option value="events">Events</option>
                        <option value="gyms">Gyms</option>
                      </Form.Select>
                    </div>
                    <div className="search-input" style={{ border: "none" }}>
                      <div className="form-group mb-0">
                        <label>Where </label>
                        <Form.Select
                          aria-label="Select Category"
                          name="location"
                          value={location}
                          onChange={handleLocChange}
                          style={{ border: "none", backgroundColor: "#F9F9F6" }}
                        >
                          <option value="">Choose Location</option>
                          {category1 === "turfs" &&
                            data.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          {category1 === "events" &&
                            data2.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          {category1 === "gyms" &&
                            data3.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="search-btn">
                      <button className="btn" type="submit">
                        <i className="feather-search"></i>
                        <span className="search-text">Search</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="banner-imgs text-center aos" data-aos="fade-up">
                <img
                  className="img-fluid hero-section-img01"
                  src="assets/Homeimg/Home-hero-01.jpeg"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
