import { useEffect, useState } from "react";
import "./Dateandtimepicker.css";
import Header from "../Home/Header";
import axios from "axios";
import React from "react";

const Dateandtimepicker = (props) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  const handledate = (event) => {
    setDate(event.target.value);
    props.setDate(event.target.value);
  };

  const handleDivClick = (index) => {
    if (props.time[index].disable) {
      return false;
    }
    if (props.data.type === "turf") {
      setTime((prevState) =>
        prevState.map((time, i) =>
          i === index ? { ...time, isbooked: !time.isbooked } : time
        )
      );
      props.setTime((prevState) =>
        prevState.map((time, i) =>
          i === index ? { ...time, isbooked: !time.isbooked } : time
        )
      );
    } else if (props.data.type === "gym") {
      for (let index1 = index; index1 < 3 + index; index1++) {
        setTime((prevState) =>
          prevState.map((time, i) =>
            i === index1 ? { ...time, isbooked: !time.isbooked } : time
          )
        );
        props.setTime((prevState) =>
          prevState.map((time, i) =>
            i === index1 ? { ...time, isbooked: !time.isbooked } : time
          )
        );
      }
    } else {
      for (
        let index1 = index;
        index1 < parseInt(props.data.totalhour) + index;
        index1++
      ) {
        setTime((prevState) =>
          prevState.map((time, i) =>
            i === index1 ? { ...time, isbooked: !time.isbooked } : time
          )
        );
        props.setTime((prevState) =>
          prevState.map((time, i) =>
            i === index1 ? { ...time, isbooked: !time.isbooked } : time
          )
        );
      }
    }
  };

  useEffect(() => {
    setDate(props.date);
    setTime(props.time);
  }, [props.date, props.time]);

  return (
    <>
      <div className="container mt-4">
        <div className="mb-3">
          <label htmlFor="date-picker" className="form-label">
            Select Date
          </label>
          <input
            type="date"
            id="date-picker"
            className="form-control"
            onChange={handledate}
            value={date}
            min={today}
          />
        </div>

        <div className="row">
          {time.map((time, index) => (
            <div
              key={index}
              className={`col-6 col-md-4 col-lg-3 mb-3 ${
                time.isbooked ? "bg-success text-white" : "bg-light"
              } 
              ${time.disable ? "time-disable" : ""}
              `}
              onClick={() => handleDivClick(index)}
              style={{
                cursor: "pointer",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>{time.selecttime}</span>
                <span
                  className={`badge ${
                    time.isbooked
                      ? "bg-white text-success"
                      : "bg-secondary text-white"
                  }`}
                >
                  {time.isbooked ? "Booked" : "Available"}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dateandtimepicker;
