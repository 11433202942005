import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "./Explorefaq.css";
import React from "react";

const FrequentlyAskedQuestions = () => {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [faqData, setFaqData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEditing) {
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkhomeExploreFrequentlyAskedQuestions/${editData.id}`,
          formData
        );
        alert("Data Updated Successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkhomeExploreFrequentlyAskedQuestions",
          formData
        );
        alert("Data Saved Successfully");
      }

      setFormData({
        question: "",
        answer: "",
      });
      setShowModal(false);
      getData(); // Refresh the data list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeExploreFrequentlyAskedQuestions"
      );
      setFaqData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (faq) => {
    setEditData(faq);
    setFormData({
      question: faq.question,
      answer: faq.answer,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this FAQ?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkhomeExploreFrequentlyAskedQuestions/${id}`
        );
        alert("FAQ deleted successfully");
        getData();
      } catch (error) {
        console.log("Failed to delete FAQ", error);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      question: "",
      answer: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <div className="faq-container">
        <h2>{isEditing ? "Edit FAQ" : "Add FAQ"}</h2>
        <form onSubmit={handleSubmit} className="faq-form">
          <div className="mb-3">
            <label htmlFor="question" className="form-label">
              Question
            </label>
            <input
              type="text"
              className="form-control"
              id="question"
              name="question"
              value={formData.question}
              onChange={handleChange}
              placeholder="Enter your question here"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="answer" className="form-label">
              Answer
            </label>
            <input
              type="text"
              className="form-control"
              id="answer"
              name="answer"
              value={formData.answer}
              onChange={handleChange}
              placeholder="Enter the answer here"
            />
          </div>

          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <div className="faq-preview-container">
          <h3>FAQs</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {faqData.map((faq, index) => (
                <tr key={faq.id}>
                  <td>{index + 1}</td>
                  <td>{faq.question}</td>
                  <td>{faq.answer}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(faq)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(faq.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit FAQ" : "Add FAQ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="question" className="form-label">
                Question
              </label>
              <input
                type="text"
                className="form-control"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="answer" className="form-label">
                Answer
              </label>
              <input
                type="text"
                className="form-control"
                id="answer"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
              />
            </div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Update" : "Save"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FrequentlyAskedQuestions;
