import { useEffect, useState } from "react";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import axios from "axios";
import React from "react";

function Admin() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkregisteredturfs"
      );
      setData(response.data);
      console.log(response.data, "getdata");
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleDelete = async (id) => {
    const Isconfirm = window.confirm("are you sure want to delete this turf ?");
    if (!Isconfirm) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkregisteredturfs/${id}`
        );
        // Remove the deleted item from the local state
        // setData(data.filter((item) => item.id !== id));
        getdata();
        console.log("Item deleted successfully");
      } catch (error) {
        console.error("Error deleting item", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleIsFeatured = async (event, item) => {
    console.log(event.target.checked, item);
    const isfeatured = new FormData();
    isfeatured.append("isFeatured", event.target.checked);
    try {
      const response = await axios.put(
        `https://api.dremerz.net/api/lawnlinkregisteredturfs/${item.id}`,
        isfeatured
      );
      getdata();
    } catch (error) {
      console.error("failed", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const filteredData = data.filter(
    (item) =>
      (item.turfname || "").toLowerCase().includes(searchTerm) ||
      (item.ownername || "").toLowerCase().includes(searchTerm) ||
      (item.location || "").toLowerCase().includes(searchTerm) ||
      (item.contactNo || "").includes(searchTerm) ||
      (item.officialemail || "").toLowerCase().includes(searchTerm) ||
      (item.isFeatured || "").toLowerCase().includes(searchTerm)
  );

  return (
    <>
      <Navbaradmin />
      <div className="container" style={{ marginTop: "100px" }}>
        <h2>Turfs Management</h2>
        <p>To Search Featured Turfs (Type... True or False) </p>

        <div
          className="admin-search-bar"
          style={{ textAlign: "right", marginBottom: "20px" }}
        >
          <input
            type="text"
            placeholder="Search Here..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
        </div>

        <table className="admin-user-table">
          <thead className="admin-userlist-header">
            <tr>
              <th>S.No</th>
              <th>turfname</th>
              <th>ownername</th>
              <th>contactNo</th>
              <th>location</th>
              <th>officialemail</th>
              <th>IsFeatured</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, i) => (
                <tr key={item.id}>
                  <td>{i + 1}</td>
                  <td>{item.turfname}</td>
                  <td>{item.ownername}</td>
                  <td>{item.contactNo}</td>
                  <td>{item.location}</td>
                  <td>{item.officialemail}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={i + "check"}
                      id={i + "check"}
                      checked={item.isFeatured === "true" ? true : false}
                      onChange={(e) => {
                        handleIsFeatured(e, item);
                      }}
                    ></input>
                    <label htmlFor={i + "check"}>
                      &nbsp;
                      {item.isFeatured === "true" ? "Featured" : "Non-Featured"}
                    </label>
                  </td>

                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Admin;
