import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BookingPage from "./Booknow";
import BookingConfirmation from "./Orderconfirmation";
import Payment from "./Payment";
import Header from "../Home/Header";
import React from "react";

const BookingHome = () => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();
  const actdata = location.state?.data || {};
  const [data, setData] = useState(actdata);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      {data != null ? (
        <>
          <Header />

          {activeStep == 1 ? (
            <BookingPage
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              data={data}
              setData={setData}
            />
          ) : (
            ""
          )}

          {activeStep == 2 ? (
            <BookingConfirmation
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              data={data}
              setData={setData}
            />
          ) : (
            ""
          )}

          {activeStep == 3 ? (
            <Payment
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              data={data}
              setData={setData}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default BookingHome;
