import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import axios from "axios";
import Header from "../Home/Header";
import { Spinner } from "react-bootstrap";
import { json } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Gymregistration = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    rating: "",
    review: "",
    turfname: "",
    priceperhour: "",
    location: "",
    contactNo: "",
    description: "",
    fulladdress: "",
    officialemail: "",

    overview: [{ heading: "" }],
    includes: [{ item: "" }],
    rules: [{ rule: "" }],
    amenities: [{ amenity: "" }],
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(null);

  const [imaget1, setImaget1] = useState(null);
  const [imaget2, setImaget2] = useState(null);
  const [imaget3, setImaget3] = useState(null);
  const [imaget4, setImaget4] = useState(null);
  const [imaget5, setImaget5] = useState(null);
  const [imaget6, setImaget6] = useState(null);

  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log(user.email, "email");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };
  const handleSectionChange = (section, index, event) => {
    const newSectionData = formData[section].map((item, i) => {
      if (i === index) {
        return { ...item, [event.target.name]: event.target.value };
      }
      return item;
    });
    setFormData({ ...formData, [section]: newSectionData });
  };

  const handleImageChange = (event, field) => {
    const selectedImage = event.target.files[0];
    switch (field) {
      case "mainImage":
        setImage(selectedImage);
        break;
      case "imaget1":
        setImaget1(selectedImage);
        break;
      case "imaget2":
        setImaget2(selectedImage);
        break;
      case "imaget3":
        setImaget3(selectedImage);
        break;
      case "imaget4":
        setImaget4(selectedImage);
        break;
      case "imaget5":
        setImaget5(selectedImage);
        break;
      case "imaget6":
        setImaget6(selectedImage);
        break;
      default:
        console.warn("Invalid field");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.rating) newErrors.rating = "Rating is required";
    if (!formData.review) newErrors.review = "Review Count is required";
    if (!formData.turfname) newErrors.turfname = "Gym Name is required";
    if (!formData.priceperhour)
      newErrors.priceperhour = "priceperhour is required";

    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.contactNo) newErrors.contactNo = "Contact Number is required";
    if (!formData.description)
      newErrors.description = "Description is required";
    if (!formData.fulladdress)
      newErrors.fulladdress = "fulladdress is required";

    ["overview", "includes", "rules", "amenities"].forEach((section) => {
      formData[section].forEach((item, index) => {
        if (!item[Object.keys(item)[0]]) {
          newErrors[section] = newErrors[section] || [];
          newErrors[section][index] = {
            [Object.keys(item)[0]]: `${
              section.charAt(0).toUpperCase() + section.slice(1)
            } is required`,
          };
        }
      });
    });

    return newErrors;
  };

  const handleNavigation = () => {
    navigate("/myprofile"); // Now you can use navigate to programmatically go to a route
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append("userid", user.id);
    data.append("ownername", user.username);

    data.append("rating", formData.rating);
    data.append("review", formData.review);
    data.append("turfname", formData.turfname);
    data.append("totalprice", formData.priceperhour);
    data.append("location", formData.location.toLowerCase());
    data.append("contactNo", formData.contactNo);
    data.append("description", formData.description);
    data.append("fulladdress", formData.fulladdress);
    data.append("overview", JSON.stringify(formData.overview));
    data.append("includes", JSON.stringify(formData.includes));
    data.append("rules", JSON.stringify(formData.rules));
    data.append("amenities", JSON.stringify(formData.amenities));
    data.append("officialemail", user.email);
    data.append("type", "gym");
    data.append("isFeatured", "false");

    if (image) {
      data.append("image", image);
    }

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/lawnlinkregisteredgyms",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.message === "Success") {
        const { id } = response.data;
        console.log(id, "id");

        const galleryImages = [
          { fieldName: "galleryImage1", file: imaget1 },
          { fieldName: "galleryImage2", file: imaget2 },
          { fieldName: "galleryImage3", file: imaget3 },
          { fieldName: "galleryImage4", file: imaget4 },
          { fieldName: "galleryImage5", file: imaget5 },
          { fieldName: "galleryImage6", file: imaget6 },
        ];
        // Upload gallery images with unique field names
        for (let i = 0; i < galleryImages.length; i++) {
          const { fieldName, file } = galleryImages[i];
          if (file) {
            const imageFormData = new FormData();
            imageFormData.append(fieldName, file);

            try {
              await axios.put(
                `https://api.dremerz.net/api/lawnlinkregisteredgyms/${id}`,
                imageFormData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
            } catch (error) {
              console.error(`Failed to upload ${fieldName}`, error);
            }
          }
        }

        setSuccessMessage("Post successful!");
      } else {
        setErrorMessage("Post failed. Please try again.");
      }
    } catch (error) {
      console.error("Registration failed", error);
      setErrorMessage("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  const renderFields = (section, label) => {
    return formData[section].map((item, index) => (
      <Form.Group controlId={`${section}-${index}`} key={index}>
        <Row>
          <Col xs={10}>
            <Form.Label>
              {label} {index + 1}
            </Form.Label>
            <Form.Control
              type="text"
              name={Object.keys(item)[0]}
              value={item[Object.keys(item)[0]]}
              onChange={(event) => handleSectionChange(section, index, event)}
              isInvalid={!!errors[section]?.[index]?.[Object.keys(item)[0]]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[section]?.[index]?.[Object.keys(item)[0]]}
            </Form.Control.Feedback>
          </Col>
          <Col xs={2} className="d-flex align-items-end">
            <button
              onClick={() => deleteField(section, index)}
              disabled={formData[section].length === 1}
              style={{
                color: "white",
                backgroundColor: "red",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Delete
            </button>
          </Col>
        </Row>
      </Form.Group>
    ));
  };

  const addField = (section) => {
    const newField = {};
    newField[Object.keys(formData[section][0])[0]] = "";
    setFormData({ ...formData, [section]: [...formData[section], newField] });
  };

  const deleteField = (section, index) => {
    const newSectionData = formData[section].filter((_, i) => i !== index);
    setFormData({ ...formData, [section]: newSectionData });
  };

  return (
    <>
      <Header />
      <div className="turfreg-content">
        <div
          className="turfreg-container"
          style={{ marginTop: window.innerWidth <= 786 ? "" : "60px" }}
        >
          <div className="turfreg-section-heading aos">
            <h2>
              <span>Gym</span> Registration
            </h2>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              controlId="tournamentPoster"
              className="turfreg-form-group"
            >
              <Form.Label className="turfreg-form-label">
                Upload Your Gym Main Images Here
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, "mainImage")}
                className="turfreg-form-control"
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group
                  controlId="tournamentPoster1"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(1)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget1")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="tournamentPoster2"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(2)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget2")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="tournamentPoster3"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(3)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget3")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  controlId="tournamentPoster4"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(4)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget4")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="tournamentPoster5"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(5)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget5")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="tournamentPoster6"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    GalleryImage(6)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, "imaget6")}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="turfname" className="turfreg-form-group">
              <Form.Label className="turfreg-form-label">
                Fullname Of Gym
              </Form.Label>
              <Form.Control
                type="turfname-local"
                value={formData.turfname}
                onChange={handleChange}
                isInvalid={!!errors.turfname}
                className="turfreg-form-control"
              />
              <Form.Control.Feedback
                type="invalid"
                className="turfreg-form-feedback"
              >
                {errors.turfname}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="turfreg-spacer">
              <Col>
                <Form.Group controlId="rating" className="turfreg-form-group">
                  <Form.Label className="turfreg-form-label">
                    Rating Out Of 5
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.rating}
                    onChange={handleChange}
                    isInvalid={!!errors.rating}
                    min="0"
                    max="5"
                    step="1"
                    pattern="[0-5]"
                    className="turfreg-form-control"
                    onInput={(e) => {
                      if (e.target.value.length > 1)
                        e.target.value = e.target.value.slice(0, 1);
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="turfreg-form-feedback"
                  >
                    {errors.rating}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="review" className="turfreg-form-group">
                  <Form.Label className="turfreg-form-label">
                    Number Of Reviews
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.review}
                    onChange={handleChange}
                    isInvalid={!!errors.review}
                    className="turfreg-form-control"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="turfreg-form-feedback"
                  >
                    {errors.review}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="turfreg-spacer">
              <Col>
                <Form.Group
                  controlId="priceperhour"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    Price Per Slot (3 Hours)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.priceperhour}
                    onChange={handleChange}
                    className="turfreg-form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="location" className="turfreg-form-group">
                  <Form.Label className="turfreg-form-label">
                    District
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.location}
                    onChange={handleChange}
                    isInvalid={!!errors.location}
                    className="turfreg-form-control"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="turfreg-form-feedback"
                  >
                    {errors.location}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="turfreg-spacer">
              <Col>
                <Form.Group
                  controlId="contactNo"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    Contact No
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formData.contactNo}
                    onChange={handleChange}
                    isInvalid={!!errors.contactNo}
                    minLength={10}
                    maxLength={10}
                    className="turfreg-form-control"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="turfreg-form-feedback"
                  >
                    {errors.contactNo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group
                  controlId="officialemail"
                  className="turfreg-form-group"
                >
                  <Form.Label className="turfreg-form-label">
                    Official Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={formData.officialemail}
                    onChange={handleChange}
                    isInvalid={!!errors.officialemail}
                    className="turfreg-form-control"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="turfreg-form-feedback"
                  >
                    {errors.officialemail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
            </Row>

            <Form.Group controlId="description" className="turfreg-form-group">
              <Form.Label className="turfreg-form-label">
                Description
              </Form.Label>
              <Form.Control
                type="text"
                value={formData.description}
                onChange={handleChange}
                isInvalid={!!errors.description}
                className="turfreg-form-control"
              />
              <Form.Control.Feedback
                type="invalid"
                className="turfreg-form-feedback"
              >
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="fulladdress" className="turfreg-form-group">
              <Form.Label className="turfreg-form-label">
                Full Address
              </Form.Label>
              <Form.Control
                type="text"
                value={formData.fulladdress}
                onChange={handleChange}
                isInvalid={!!errors.fulladdress}
                className="turfreg-form-control"
              />
              <Form.Control.Feedback
                type="invalid"
                className="turfreg-form-feedback"
              >
                {errors.fulladdress}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="turfreg-spacer">
              <h3>Overview</h3>
              {renderFields("overview", "Overview Heading")}
              <Button
                variant="secondary"
                className="turfreg-add-field-btn"
                onClick={() => addField("overview")}
              >
                Add More Overview
              </Button>

              <h3>Includes</h3>
              {renderFields("includes", "Include Item")}
              <Button
                variant="secondary"
                className="turfreg-add-field-btn"
                onClick={() => addField("includes")}
              >
                Add More Includes
              </Button>

              <h3>Rules</h3>
              {renderFields("rules", "Rule")}
              <Button
                variant="secondary"
                className="turfreg-add-field-btn"
                onClick={() => addField("rules")}
              >
                Add More Rules
              </Button>

              <h3>Amenities</h3>
              {renderFields("amenities", "Amenity")}
              <Button
                variant="secondary"
                className="turfreg-add-field-btn"
                onClick={() => addField("amenities")}
              >
                Add More Amenities
              </Button>
            </div>
            <br />

            <Button
              variant="primary"
              type="submit"
              className="turfreg-submit-button"
            >
              {loading ? <Spinner /> : "Submit"}
            </Button>
          </Form>
          {successMessage && (
            <Alert
              variant="success"
              className="turfreg-alert turfreg-alert-success"
            >
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              variant="danger"
              className="turfreg-alert turfreg-alert-danger"
            >
              {errorMessage}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default Gymregistration;
