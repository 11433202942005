import Convenienthours from "./Convenienthours";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import React from "react";

const Adminhourly = () => {
  return (
    <>
      <Navbaradmin />
      <Convenienthours />
    </>
  );
};
export default Adminhourly;
