import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "./Exploreadmin.css";
import React from "react";

const Exploreadmin = () => {
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    details: "",
    instalink: "",
  });
  const [image, setImage] = useState(null);
  const [teamData, setTeamData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVenueImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    // Append the image file
    if (image) {
      formDataToSend.append("imgSrc", image);
    }

    try {
      if (isEditing) {
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkOurTeam/${editData.id}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Data Updated Successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkOurTeam",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Data Saved Successfully");
      }

      setFormData({
        name: "",
        designation: "",
        details: "",
        instalink: "",
      });
      setImage(null);
      setShowModal(false);
      getData(); // Refresh the data list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkOurTeam"
      );
      setTeamData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (teamMember) => {
    setEditData(teamMember);
    setFormData({
      name: teamMember.name,
      designation: teamMember.designation,
      details: teamMember.details,
      instalink: teamMember.instalink,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this team member?")) {
      try {
        await axios.delete(`https://api.dremerz.net/api/lawnlinkOurTeam/${id}`);
        alert("Team member deleted successfully");
        getData();
      } catch (error) {
        console.log("Failed to delete team member", error);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      name: "",
      designation: "",
      details: "",
      instalink: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <div className="team-container">
        <h2>{isEditing ? "Edit Team Member" : "Add Team Member"}</h2>
        <form onSubmit={handleSubmit} className="team-form">
          <div className="mb-3">
            <label htmlFor="imgSrc" className="form-label">
              Image Source
            </label>
            <input
              type="file"
              className="form-control"
              id="imgSrc"
              name="imgSrc"
              accept="image/*"
              onChange={handleVenueImageChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Name"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="designation" className="form-label">
              Designation
            </label>
            <input
              type="text"
              className="form-control"
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              placeholder="Enter Designation"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="details" className="form-label">
              Details
            </label>
            <input
              type="text"
              className="form-control"
              id="details"
              name="details"
              value={formData.details}
              onChange={handleChange}
              placeholder="Enter Details"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="instalink" className="form-label">
              Insta Link
            </label>
            <input
              type="text"
              className="form-control"
              id="instalink"
              name="instalink"
              value={formData.instalink}
              onChange={handleChange}
              placeholder="Enter Instagram Link"
            />
          </div>

          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <div className="team-preview-container">
          <h3>Team Members</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Details</th>
                <th>Insta Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teamData.map((member, index) => (
                <tr key={member.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={`https://api.dremerz.net/uploads/${member.imgSrc}`}
                      alt={member.name}
                      width="100"
                    />
                  </td>
                  <td>{member.name}</td>
                  <td>{member.designation}</td>
                  <td
                    style={{
                      wordBreak: "break-word",
                      maxWidth: "300px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {member.details}
                  </td>
                  <td>
                    <a
                      href={member.instalink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        wordBreak: "break-word",
                        maxWidth: "300px",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {member.instalink}
                    </a>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(member)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(member.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Team Member" : "Add Team Member"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="imgSrc" className="form-label">
                Image Source
              </label>
              <input
                type="file"
                className="form-control"
                id="imgSrc"
                name="imgSrc"
                accept="image/*"
                onChange={handleVenueImageChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="designation" className="form-label">
                Designation
              </label>
              <input
                type="text"
                className="form-control"
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="details" className="form-label">
                Details
              </label>
              <input
                type="text"
                className="form-control"
                id="details"
                name="details"
                value={formData.details}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="instalink" className="form-label">
                Insta Link
              </label>
              <input
                type="text"
                className="form-control"
                id="instalink"
                name="instalink"
                value={formData.instalink}
                onChange={handleChange}
              />
            </div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Update" : "Save"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Exploreadmin;
