import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap modal
import "./Convenienthours.css";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
// Import as a module in your JS
import "react-bootstrap-typeahead/css/Typeahead.css";
import React from "react";

const Convenienthours = () => {
  const [formData, setFormData] = useState({
    totalhour: "",
    totalprice: "",
  });
  const [image, setImage] = useState(null);
  const [venueData, setVenueData] = useState([]); // Store fetched data
  const [editData, setEditData] = useState(null); // For editing specific data
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [isEditing, setIsEditing] = useState(false); // Toggle between add and edit modes
  const [singleSelections, setSingleSelections] = useState([]);
  const [offerData, setOfferData] = useState([]);
  // const options = [
  //   { label: "namenae", value: "namenae" },
  //   { label: "namenae1", value: "namenae1" },
  // ];
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle image change
  const handleVenueImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  // Handle form submission (for both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    const keysToRemove = ["amenities", "includes", "overview", "rules", "id"];

    Object.keys(singleSelections[0]).forEach((key) => {
      if (!keysToRemove.includes(key)) {
        formDataToSend.append(key, singleSelections[0][key]);
      }
    });

    try {
      if (isEditing) {
        // Update existing entry
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkregisteredturfsoffers/${editData.id}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Data Updated Successfully");
      } else {
        // Add new entry
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkregisteredturfsoffers",
          formDataToSend
        );
        alert("Data Saved Successfully");
      }

      setFormData({
        totalhour: "",
        totalprice: "",
      });
      setSingleSelections([]);
      setShowModal(false); // Close the modal
      getData(); // Refresh the data list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  // Fetch data from the server
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkregisteredturfs"
      );
      setVenueData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  const getofferData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkregisteredturfsoffers"
      );
      setOfferData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData(); // Fetch data on component load
  }, []);

  // Handle edit functionality
  const handleEdit = (item) => {
    setEditData(item);
    setFormData({
      totalhour: item.totalhour,
      totalprice: item.totalprice,
    });
    setIsEditing(true);
    setShowModal(true); // Open modal for editing
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkregisteredturfsoffers/${id}`
        );
        alert("Entry deleted successfully");
        getData(); // Refresh the data list
      } catch (error) {
        console.log("Failed to delete entry", error);
      }
    }
  };

  // Close modal
  const handleClose = () => {
    setShowModal(false);
    setFormData({
      totalhour: "",
      totalprice: "",
    });
    setIsEditing(false);
  };

  useEffect(() => {
    console.log(singleSelections, "sss");
  }, [singleSelections]);
  useEffect(() => {
    getofferData();
  }, []);

  return (
    <>
      <div className="hourly-packages-container">
        <h2>{isEditing ? "Edit Hourly Package" : "Add Hourly Package"}</h2>
        <form onSubmit={handleSubmit} className="hourly-packages-form">
          <div className="mb-3">
            <label htmlFor="turfName" className="form-label">
              Turf Name
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey={"turfname"}
              onChange={setSingleSelections}
              options={venueData}
              placeholder="Choose a state..."
              selected={singleSelections}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="totalhour" className="form-label">
              Total Offer Hours Package
            </label>
            <input
              type="text"
              className="form-control"
              id="totalhour"
              name="totalhour"
              value={formData.totalhour}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="totalprice" className="form-label">
              Total Offer Price
            </label>
            <input
              type="text"
              className="form-control"
              id="totalprice"
              name="totalprice"
              value={formData.totalprice}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <h3>Hourly Packages List</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Turf Name</th>
              <th>Price Per Hour</th>
              <th>Offer Hour</th>
              <th>Offer Price</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {offerData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <img
                    src={`https://api.dremerz.net/uploads/${item.image}`}
                    alt={item.turfname}
                    width="100px"
                    height="30px"
                  />
                </td>
                <td>{item.turfname}</td>
                <td>{item.priceperhour}</td>
                <td>{item.totalhour}</td>

                <td>{item.totalprice}</td>
                <td>{item.location}</td>
                <td></td>
                <td>
                  {/* <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </button> */}
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Hourly Package" : "Add Hourly Package"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="imgSrc" className="form-label">
                Image Source
              </label>
              <input
                type="file"
                className="form-control"
                id="imgSrc"
                name="imgSrc"
                accept="image/*"
                onChange={handleVenueImageChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="turfName" className="form-label">
                Turf Name
              </label>
              <input
                type="text"
                className="form-control"
                id="turfName"
                name="turfName"
                value={formData.turfName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                type="text"
                className="form-control"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="pricePerHour" className="form-label">
                Price Per Hour
              </label>
              <input
                type="text"
                className="form-control"
                id="pricePerHour"
                name="pricePerHour"
                value={formData.pricePerHour}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="totalhour" className="form-label">
                Total Offer Hours
              </label>
              <input
                type="text"
                className="form-control"
                id="totalhour"
                name="totalhour"
                value={formData.totalhour}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="totalprice" className="form-label">
                Total Offer Price
              </label>
              <input
                type="text"
                className="form-control"
                id="totalprice"
                name="totalprice"
                value={formData.totalprice}
                onChange={handleChange}
              />
            </div>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              {isEditing ? "Update" : "Save"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Convenienthours;
