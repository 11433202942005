import { useEffect, useState } from "react";
import "./Navbaradin.css";
import { Navigate, useNavigate } from "react-router-dom";
import React from "react";

const Navbarheader = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const navvigateTo = (url) => {
    if (userData != null) {
      navigate(url);
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
  }, []);

  return (
    <header className="header header-trans new-header-trans">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <a id="mobile_btn" href="#">
              <span className="bar-icon"></span>
            </a>
            <a
              href=""
              onClick={() => navvigateTo("/home")}
              className="navbar-brand logo"
            >
              <img
                src={`${process.env.PUBLIC_URL}/Listimg/Lawnlogo.jpeg`}
                className="img-fluid lawn-logo"
                alt="Logo"
              />
            </a>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <a href="#" className="menu-logo">
                <img
                  src={`${process.env.PUBLIC_URL}/Listimg/Lawnlogo.jpeg`}
                  className="img-fluid lawn-logo"
                  alt="Logo"
                />
              </a>
              <a id="menu_close" className="menu-close" href="#">
                <i className="fas fa-times"></i>
              </a>
            </div>
            <ul className="main-nav">
              <li>
                <a href="/superadmin+asis+2024+jovin+lawnlink+ll+@+aj">Home</a>
              </li>

              <li>
                <a href="/adminhourly">HourlyOffers</a>
              </li>
              <li>
                <a href="/adminavailoffers">Availableoffers</a>
              </li>
              <li>
                <a href="/exploreadmin">Explore</a>
              </li>
              <li>
                <a href="/contactusadmin">Contact</a>
              </li>
              <li>
                <a href="/userlists+ll+2024+asis@jovin">Users</a>
              </li>
              <li>
                <a href="/turfslists+ll+2024+asis@jovin">Turfs</a>
              </li>
              <li>
                <a href="/eventslists+ll+2024+asis@jovin">Events</a>
              </li>
              <li>
                <a href="/gymslists+ll+2024+asis@jovin">Gyms</a>
              </li>
              <li>
                <a href="/enquirylist">Enquiry List</a>
              </li>
              <li>
                <a href="/invoicelist">Invoice List</a>
              </li>

              {/* <li className="has-submenu">
                <a href="#">
                  Turf <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/shopping">Turf List</a>
                  </li>
                  <li>
                    <a href="/hourlypackage">Hourly Package</a>
                  </li>
                  <li>
                    <a href="/availableoffers">Available Offers</a>
                  </li>
                </ul>
              </li>

              <li className="has-submenu">
                <a href="#">
                  Bookings <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="" onClick={() => navvigateTo("/userdashboard")}>
                      User Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="" onClick={() => navvigateTo("/mybookings")}>
                      My Bookings
                    </a>
                  </li>
                </ul>
              </li>

              <li className="has-submenu">
                <a href="#">
                  Explore <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/aboutushome">Our Team</a>
                  </li>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/termsandconditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" onClick={() => navvigateTo("/events")}>
                  Events
                </a>
              </li>
              <li>
                <a href="" onClick={() => navvigateTo("/turfhost")}>
                  Host Match
                </a>
              </li>

              <li>
                <a href="contactus">Contact Us</a>
              </li> */}
            </ul>
          </div>

          {/* <ul className="nav header-navbar-rht">
            <li className="nav-item">
              {userData ? (
                <div className="nav-link btn btn-white log-register">
                  <a href="/myprofile">
                    <span>
                      <i className="feather-user"></i>
                    </span>
                    {userData.username}
                  </a>
                </div>
              ) : (
                <div className="nav-link btn btn-white log-register">
                  <a href="/signin">
                    <span>
                      <i className="feather-users"></i>
                    </span>
                    Login
                  </a>
                  / <a href="/signup">Register</a>
                </div>
              )}
            </li>
          </ul> */}
        </nav>
      </div>
    </header>
  );
};

export default Navbarheader;
