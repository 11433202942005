import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap modal
import "./Faqadmin.css";
import React from "react";

const FrequentlyAskedQuestions = () => {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [faqData, setFaqData] = useState([]); // Store fetched FAQ data
  const [editData, setEditData] = useState(null); // For editing specific data
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [isEditing, setIsEditing] = useState(false); // Toggle between add and edit modes

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission (for both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      if (isEditing) {
        // Update existing FAQ
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkhomeFrequentlyAskedQuestions/${editData.id}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("FAQ Updated Successfully");
      } else {
        // Add new FAQ
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkhomeFrequentlyAskedQuestions",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("FAQ Saved Successfully");
      }

      setFormData({
        question: "",
        answer: "",
      });
      setShowModal(false); // Close the modal
      getData(); // Refresh the FAQ list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  // Fetch FAQ data from the server
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeFrequentlyAskedQuestions"
      );
      setFaqData(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData(); // Fetch data on component load
  }, []);

  // Handle edit functionality
  const handleEdit = (faq) => {
    setEditData(faq);
    setFormData({
      question: faq.question,
      answer: faq.answer,
    });
    setIsEditing(true);
    setShowModal(true); // Open modal for editing
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this FAQ?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkhomeFrequentlyAskedQuestions/${id}`
        );
        alert("FAQ deleted successfully");
        getData(); // Refresh the FAQ list
      } catch (error) {
        console.log("Failed to delete FAQ", error);
      }
    }
  };

  // Close modal
  const handleClose = () => {
    setShowModal(false);
    setFormData({
      question: "",
      answer: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        <form onSubmit={handleSubmit} className="faq-form">
          <div className="mb-3">
            <label htmlFor="question" className="form-label">
              Question
            </label>
            <input
              type="text"
              className="form-control"
              id="question"
              name="question"
              value={formData.question}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="answer" className="form-label">
              Answer
            </label>
            <input
              type="text"
              className="form-control"
              id="answer"
              name="answer"
              value={formData.answer}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <h3>Preview of FAQs</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Answer</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {faqData.map((faq, index) => (
              <tr key={faq.id}>
                <td>{index + 1}</td>
                <td>{faq.question}</td>
                <td
                  style={{
                    wordBreak: "break-word",
                    maxWidth: "300px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {faq.answer}
                </td>

                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(faq)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(faq.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit FAQ" : "Add FAQ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="question" className="form-label">
                Question
              </label>
              <input
                type="text"
                className="form-control"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="answer" className="form-label">
                Answer
              </label>
              <input
                type="text"
                className="form-control"
                id="answer"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              {isEditing ? "Update" : "Save"}
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <br></br>
      <br></br>
      <br></br>
      <h2 style={{ textAlign: "center" }}>END</h2>
    </>
  );
};

export default FrequentlyAskedQuestions;
